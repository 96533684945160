import { FileResult, FileType, isFileLoaded, readFiles } from './file';

export type ImageType = {
  type: string;
  lastModified: number;
  name: string;
  src: string;
  width: number;
  height: number;
  size: number;
  originalFile: File;
};

type ImageLoadedType = {
  type: 'image_loaded';
  image: ImageType;
};

type ImageLoadErrorType = {
  type: 'image_load_error';
};

type ImageLoadAbortedType = {
  type: 'image_load_aborted';
};

type ImageResult = ImageLoadedType | ImageLoadErrorType | ImageLoadAbortedType;

function isImageLoaded(result: ImageResult): result is ImageLoadedType {
  return result.type === 'image_loaded';
}

function readImageFromFile(file: FileType): Promise<ImageResult> {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () =>
      resolve({
        type: 'image_loaded',
        image: {
          src: file.data,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          name: file.name,
          width: image.naturalWidth,
          height: image.naturalHeight,
          originalFile: file.originalFile,
        },
      })
    );
    image.addEventListener('error', () => {
      resolve({
        type: 'image_load_error',
      });
    });
    image.addEventListener('abort', () =>
      resolve({
        type: 'image_load_aborted',
      })
    );
    image.src = file.data;
  });
}

function fileResultsToImageResults(
  results: FileResult[]
): Promise<ImageResult[]> {
  return Promise.all(
    results
      .filter(isFileLoaded)
      .map((result) => result.file)
      .map(readImageFromFile)
  );
}

export function readImagesFromFiles(files: File[]): Promise<ImageType[]> {
  return readFiles(files)
    .then(fileResultsToImageResults)
    .then((images) =>
      images.filter(isImageLoaded).map((result) => result.image)
    );
}

const imageMimeTypeRgxp = /^image\/(png|jpeg|jpg|webp|heic|heif)$/;

function isImageFile(file: File): boolean {
  return imageMimeTypeRgxp.test(file.type);
}

export const accept = ['png', 'jpeg', 'webp']
  .map((extension) => `image/${extension}`)
  .join(', ');

export function getImageFilesFromFileList(fileList: FileList) {
  return Array.from(fileList).filter(isImageFile);
}

export function getImagesFromFileList(
  fileList: FileList
): Promise<ImageType[]> {
  return readImagesFromFiles(getImageFilesFromFileList(fileList));
}

// Should match the validator in the ImageUpload service.
const minWidth = 1024;
const minHeight = 576;
const maxWidth = minWidth * 8;
const maxHeight = minHeight * 8;

function gcd(a: number, b: number): number {
  return b == 0 ? a : gcd(b, a % b);
}

function getAspectRatio({ width, height }: ImageType) {
  const divisor = gcd(width, height);
  return `${width / divisor} x ${height / divisor}`;
}

export function getErrors(image: ImageType): string[] {
  const errors: string[] = [];
  const size = `${image.width} x ${image.height}`;

  if (image.width < minWidth || image.height < minHeight) {
    errors.push(
      `Deze foto is te klein (${size}). Selecteer een foto van minimaal ${minWidth} x ${minHeight}.`
    );
  } else if (image.width > maxWidth || image.height > maxHeight) {
    errors.push(
      `Deze foto is te groot (${size}). Selecteer een foto van maximaal ${maxWidth} bij ${maxHeight}.`
    );
  }

  if (image.height >= image.width) {
    errors.push(
      `Gebruik alsjeblieft een panorama foto. De aspect ratio van deze foto is ${getAspectRatio(
        image
      )}.`
    );
  }

  return errors;
}

export function isOk(image: ImageType): boolean {
  return getErrors(image).length === 0;
}
