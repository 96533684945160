export type FileType = {
  data: string;
  type: string;
  size: number;
  lastModified: number;
  name: string;
  originalFile: File;
};

type FileLoadedType = {
  type: 'file_loaded';
  file: FileType;
};

type FileErrorType = {
  type: 'file_error';
};

type FileAbortedType = {
  type: 'file_aborted';
};

export type FileResult = FileLoadedType | FileErrorType | FileAbortedType;

export function isFileLoaded(file: FileResult): file is FileLoadedType {
  return file.type === 'file_loaded';
}

function readFile(file: File): Promise<FileResult> {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        reader.result
          ? resolve({
              type: 'file_loaded',
              file: {
                type: file.type,
                name: file.name,
                size: file.size,
                lastModified: file.lastModified,
                data: reader.result.toString(),
                originalFile: file,
              },
            })
          : resolve({
              type: 'file_error',
            });
      },
      false
    );

    reader.addEventListener('error', () =>
      resolve({
        type: 'file_error',
      })
    );

    reader.addEventListener('abort', () =>
      resolve({
        type: 'file_aborted',
      })
    );

    reader.readAsDataURL(file);
  });
}

export function readFiles(files: File[]): Promise<FileResult[]> {
  return Promise.all(files.map(readFile));
}
